import React, { Fragment, useState } from "react";
import classNames from "classnames";
import classes from "./add-evaluators.styles.module.scss";
import { Paragraph } from "../../typography/paragraph/paragraph.component";
import { AddAllMembersIcon, ArrowForwardIcon } from "gx-npm-icons";
import { Loader } from "../../loader";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3606__fontUpdate } from "../../feature-flags";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../../featureFlags";
import { TypographyComponent } from "../../typography/typography.component";

type AddEvaluatorsType = {
  onAddAllEvaluatorsClick?: () => void;
  allMembersText?: string;
  addBtnText?: string;
};

const AddEvaluators = (props: AddEvaluatorsType) => {
  const [isLoading, setIsLoading] = useState(false);
  const onClick = () => {
    setIsLoading(true);
    if (props.onAddAllEvaluatorsClick) {
      props.onAddAllEvaluatorsClick();
    }
  };
  return (
    <button
      aria-label={"Add All Evaluators"}
      className={classNames(classes.gxEvaluatorBtn)}
      onClick={onClick}
      disabled={isLoading}
    >
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classNames(classes.gxAssignBtnLabel)} boldness="regular" styling={"p3"}>
            <AddAllMembersIcon /> {props.allMembersText}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph rootClassName={classNames(classes.gxAssignBtnLabel)} boldness="regular" type="p3">
            <AddAllMembersIcon /> {props.allMembersText}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      <div className={classNames(classes.gxEvaluatorAdd)}>
        <span className={classes.gxPillBg}>
          <span className={classes.gxPillAddBg}>
            <span className={classes.gxBtnContent}>
              {isLoading ? (
                <span className={classes.gxBtnLoader}>
                  <Loader size={16} variant="indeterminate" />
                </span>
              ) : (
                <Fragment>
                  {props.addBtnText}
                  <ArrowForwardIcon fillColor={colorPalette.neutrals.pearl.hex} />
                </Fragment>
              )}
            </span>
          </span>
        </span>
      </div>
    </button>
  );
};

export default AddEvaluators;
