import React, { RefObject, useContext, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { getAsyncRequest, postAsyncRequest, ScoringMode, UUID } from "gx-npm-lib";
import { AssignmentPopper, AssignmentPopperPosition, Paragraph, TextLink } from "gx-npm-ui";
import ScoringManageList from "./scoring-assignments-manage-list.component";
import { compareEvaluatorSort } from "./scoring-assignments-manage.lib";
import styles from "./scoring-assignments-manage.styles.module.scss";
import { TeamManagementAppContext } from "../../../app.context";
import { TeamMembersType } from "../../../app.types";
import { TeamManagementTabs } from "../../../lib";

type ScoringManageProps = {
  onModeChange?: () => void;
};

const ScoringManage: React.FC<ScoringManageProps> = ({ onModeChange = () => {} }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [categoryName, setCategoryName] = React.useState("");
  const [categoryId, setCategoryId] = React.useState<UUID>("");
  const [assignedEvaluators, setAssignedEvaluators] = React.useState(Array<TeamMembersType>);
  const [unassignedEvaluators, setUnassignedEvaluators] = React.useState(Array<TeamMembersType>);
  const [viewOnlyEvaluators, setViewOnlyEvaluators] = React.useState(Array<TeamMembersType>);

  const {
    isDrawerOpen,
    initiativeId,
    setErrorMessage,
    setIsErrorSnackBarOpen,
    setSelectedTab,
    setScoringTeam,
    scoringTeam,
  } = useContext(TeamManagementAppContext);
  const { mode } = scoringTeam;

  let classMode = "auto";
  let textDescription = t(
    "Team members with Owner or Contributor access to the evaluation will automatically be assigned to score all categories."
  );
  let textMode = t("Auto");

  if (mode === ScoringMode.MANUAL_ASSIGNMENT) {
    classMode = "manual";
    textDescription = t("Evaluation owners will manually assign team members to score each category.");
    textMode = t("Manual");
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      handleClose();
    }
  }, [isDrawerOpen]);

  const handleClose = () => {
    setAnchorEl(undefined);
    setIsOpen(false);
    setCategoryId("");
    setAssignedEvaluators([]);
    setUnassignedEvaluators([]);
    setViewOnlyEvaluators([]);
  };

  const loadReqScoringTeamData = (catId: UUID) => {
    if (!initiativeId) {
      return;
    }

    (async () => {
      try {
        const url = `/api/v3/initiatives/${initiativeId}/requirements/category/${catId}/scoring-team`;
        const response = await getAsyncRequest(url);
        if (response.status !== 200 || !response.data?.data) {
          throw new Error(response.data?.systemMessage?.message || "ERROR");
        }
        const assigned = (response.data?.data?.assignedEvaluators || []).sort(compareEvaluatorSort);
        const unassigned = (response.data?.data?.unassignedEvaluators || []).sort(compareEvaluatorSort);
        const viewers = (response.data?.data?.viewOnlyEvaluators || []).sort(compareEvaluatorSort);
        setAssignedEvaluators(assigned);
        setUnassignedEvaluators(unassigned);
        setViewOnlyEvaluators(viewers);
        setScoringTeam((prev) => ({
          ...prev,
          categories: prev.categories.map((category) =>
            category.id === catId ? { ...category, evaluators: assigned } : category
          ),
        }));
      } catch (err) {
        setErrorMessage(err.message);
        setIsErrorSnackBarOpen(true);
      }
    })();
  };

  const handleClickCategory = (anchorElement: RefObject<HTMLElement>, catId: UUID, catName: string) => {
    if (mode === ScoringMode.AUTO_ASSIGNMENT) {
      return;
    }
    if (anchorElement.current) {
      setAnchorEl(anchorElement.current);
    }
    setIsOpen(true);
    setCategoryName(catName);
    setCategoryId(catId);

    loadReqScoringTeamData(catId);
  };

  const handleAddEvaluators = (evaluators: string[]) => {
    (async () => {
      try {
        const url = `/api/v3/initiatives/${initiativeId}/requirements/category/${categoryId}/scoring-team/assign-evaluator`;
        const response = await postAsyncRequest(url, { emails: evaluators });
        if (response.status !== 201) {
          throw new Error(response.data?.systemMessage?.message || "ERROR");
        }

        loadReqScoringTeamData(categoryId);
      } catch (err) {
        setErrorMessage(err.message);
        setIsErrorSnackBarOpen(true);
      }
    })();
  };

  const handleConfirmRemoveEvaluator = (evaluator = { email: "", fullName: "" }) => {
    (async () => {
      try {
        const url = `/api/v3/initiatives/${initiativeId}/requirements/category/${categoryId}/scoring-team/remove-evaluator`;
        const response = await postAsyncRequest(url, { emails: [evaluator.email] });
        if (response.status !== 201) {
          throw new Error(response.data?.systemMessage?.message || "ERROR");
        }

        loadReqScoringTeamData(categoryId);
      } catch (err) {
        setErrorMessage(err.message);
        setIsErrorSnackBarOpen(true);
      }
    })();
  };

  return (
    <div aria-label={`${textMode} assignment view`} className={styles.container}>
      <div className={classNames(styles.assignmentMode, classMode)}>
        <div style={{ display: "flex" }}>
          <Paragraph rootClassName={styles.labelMode} boldness="regular" type="p3">
            {t("Assignment mode:")}
          </Paragraph>{" "}
          <Paragraph boldness="semi" rootClassName={styles.textMode} type="p3">
            {textMode}
          </Paragraph>
          <TextLink onClick={onModeChange} rootClassName={styles.linkChange} text={t("Change")} />
        </div>
        <Paragraph rootClassName={styles.textDescription} boldness="regular" type="p4">
          {textDescription}
        </Paragraph>
      </div>
      <ScoringManageList onClickCategory={handleClickCategory} selectedCategory={categoryId} />
      <AssignmentPopper
        addBtnText={t("Add")}
        allMembersText={t("All owners and contributors")}
        allTeamMembersAssignedText={t("All available team members have been assigned to score this category.")}
        addTeamMemberButtonText={t("Add team member")}
        anchor={anchorEl}
        assignedText={t("Assigned")}
        availableTeamMembersText={t("Available team members")}
        categoryName={categoryName}
        dialogRemoveAssignedEvaluator={{
          confirmText: t("Unassign"),
          text: t(`will delete any scores they have already entered for this category.`),
          title: t("Unassign team member?"),
        }}
        noTeamMembersAssignedText={t(
          "No members assigned. Choose team members from the list on the left to assign them to score."
        )}
        evaluatorsAssignedList={assignedEvaluators}
        evaluatorsAvailableList={unassignedEvaluators}
        open={isOpen}
        onClose={handleClose}
        onAddTeamMemberClick={() => {
          setSelectedTab(TeamManagementTabs.TEAM_MANAGEMENT);
        }}
        onAddAllEvaluatorsClick={() => {
          handleAddEvaluators(unassignedEvaluators.map((evaluator) => evaluator.email));
        }}
        onAssignEvaluatorClick={(evaluator) => {
          handleAddEvaluators([evaluator.email]);
        }}
        onRemoveAssignedEvaluatorClick={handleConfirmRemoveEvaluator}
        position={AssignmentPopperPosition.LEFT}
        removeBtnText={t("Remove")}
        pendingBtnText={t("Pending")}
        rootClassName={styles.popper}
        showAddAllEvaluatorsBtn={true}
        showAddTeamMemberBtn={true}
        showDialogRemoveAssignedEvaluator={true}
        viewOnlyTeamMembersText={t("View only team members")}
        viewOnlyUsersList={viewOnlyEvaluators}
      />
    </div>
  );
};

export default ScoringManage;
