import React, { useContext } from "react";
import classNames from "classnames";
import styles from "./nav-bar.styles.module.scss";
import { useTranslation } from "react-i18next";
import { TeamManagementAppContext } from "../../app.context";
import { TeamManagementTabs } from "../../lib";
import { GCOM_3696_updateHoverForLeftHandNavItems } from "../../lib/feature-flags";
import { useFeatureFlag } from "gx-npm-ui";

const TeamManagementNavBar = () => {
  const { t } = useTranslation();
  const { selectedTab, setSelectedTab } = useContext(TeamManagementAppContext);
  const isFFGCOM_3696 = useFeatureFlag(GCOM_3696_updateHoverForLeftHandNavItems);

  return (
    <section
      aria-label={
        selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS
          ? t("Scoring assignments is selected")
          : t("Team management is selected")
      }
      className={classNames(styles.navContainer, isFFGCOM_3696 && styles.navContainerGCOM_3696)}
    >
      <button
        id="teams"
        onClick={() => {
          setSelectedTab(TeamManagementTabs.TEAM_MANAGEMENT);
        }}
        className={classNames(
          "p3 semi-bold",
          styles.libraryNavItem,
          selectedTab === TeamManagementTabs.TEAM_MANAGEMENT && styles.isSelected
        )}
        tabIndex={0}
      >
        {t("Team management")}
      </button>
      <button
        id="scoring"
        onClick={() => {
          setSelectedTab(TeamManagementTabs.SCORING_ASSIGNMENTS);
        }}
        className={classNames(
          "scoring-assignments",
          "p3 semi-bold",
          styles.libraryNavItem,
          selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS && styles.isSelected
        )}
        tabIndex={0}
      >
        {t("Scoring assignments")}
      </button>
    </section>
  );
};

export default TeamManagementNavBar;
