import React, { useContext } from "react";
import classNames from "classnames";
import styles from "./nav-bar.styles.module.scss";
import { useTranslation } from "react-i18next";
import { TeamManagementAppContext } from "../../app.context";
import { TeamManagementTabs } from "../../lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const TeamManagementNavBar = () => {
  const { t } = useTranslation();
  const { selectedTab, setSelectedTab } = useContext(TeamManagementAppContext);
  const isGCOM3606FF = useFeatureFlag(GCOM_3606__fontUpdate);
  return (
    <section
      aria-label={
        selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS
          ? t("Scoring assignments is selected")
          : t("Team management is selected")
      }
      className={classNames(isGCOM3606FF ? styles.navContainerGCOM3606 : styles.navContainer)}
    >
      <button
        id="teams"
        onClick={() => {
          setSelectedTab(TeamManagementTabs.TEAM_MANAGEMENT);
        }}
        className={classNames(
          isGCOM3606FF ? "" : "p3 semi-bold",
          styles.libraryNavItem,
          selectedTab === TeamManagementTabs.TEAM_MANAGEMENT &&
            (isGCOM3606FF ? styles.isSelectedGCOM3606 : styles.isSelected)
        )}
        tabIndex={0}
        aria-label={"teams-btn"}
      >
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent
              color={selectedTab === TeamManagementTabs.TEAM_MANAGEMENT ? "yam" : "carbon"}
              styling={"p3"}
              boldness={"semi"}
            >
              {t("Team management")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>{t("Team management")}</FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </button>
      <button
        id="scoring"
        onClick={() => {
          setSelectedTab(TeamManagementTabs.SCORING_ASSIGNMENTS);
        }}
        className={classNames(
          "scoring-assignments",
          "p3 semi-bold",
          styles.libraryNavItem,
          selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS &&
            (isGCOM3606FF ? styles.isSelectedGCOM3606 : styles.isSelected)
        )}
        tabIndex={0}
        aria-label={"scoring-btn"}
      >
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent
              color={selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS ? "yam" : "carbon"}
              styling={"p3"}
              boldness={"semi"}
            >
              {t("Scoring assignments")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>{t("Scoring assignments")}</FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </button>
    </section>
  );
};

export default TeamManagementNavBar;
